import { mapActions, mapGetters } from "vuex";
import ActionModal from "@/components/reusable/GenericTabFilters/ActionModal";
import Modal from "@/components/reusable/Modal";
import OfferOnBoardModal from "@/components/reusable/OfferOnBoardModal";
import FastTrackModal from "@/components/reusable/GenericTabFilters/FastTrackModal";
import OnErrorModal from "@/components/reusable/GenericTabFilters/OnErrorModal";

export default {
  components: {
    ActionModal,
    Modal,
    OfferOnBoardModal,
    FastTrackModal,
    OnErrorModal,
  },
  data() {
    return {
      actionModal: {
        isShowPopup: false,
        job_id: null,
        details: null,
        action: null,
      },
      fastTrackModal: {
        isShowPopup: false,
        job_id: null,
        details: null,
        action: null,
        candidate: null,
        job: null,
      },
      submissionModal: {
        modalTitle: "NOTIFICATION",
        modalColor: "primary",
        modalContent: "Is the profile ready for submission?",
        isShowPopup: false,
        buttons: ["No", "Yes"],
        closeOnBackdrop: false,
      },
      interViewModal: {
        modalColor: "primary",
        modalTitle: "Interview Confirmation",
        modalContent: "Is the profile ready for interview?",
        buttons: ["No", "Yes"],
        isShowPopup: false,
      },
      offerOnBoardModal: {
        isShowPopup: false,
        job_id: null,
        details: null,
        action: null,
        title: null,
        isOffer: false,
        isOnBoard: false,
      },
      errorModal: {
        isShowPopup: false,
        candidate: null,
        job: null,
        action: null,
      },
    };
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions(["showToast", "initUpdateJobStatus", "updateCandidateJobStatus"]),
    directAction(uploadPayload) {
      this.updateCandidateJobStatus(uploadPayload);
    },
    actionModalEvent(payload) {
      const { jobDetails, selectedAction } = payload;
      const { action_id, directAction, status, reason_id } = selectedAction;
      const { job_id } = jobDetails;
      const { candidate_uid, candidate } = jobDetails;
      const actionData = {
        submission: 14,
        interview: 6,
        offer: 7,
        onboard: 8,
        fastTrack: 18,
        error: 4,
        documents: 16,
      };
      //Restricted for Demo Customer
      if (
        this.getCustomerId === 8 &&
        this.getOrgIDFromAccessToken === 7 &&
        [8, 16, 1].includes(action_id)
      ) {
        this.notificationModal.isShowPopup = true;
        return;
      }
      if (directAction) {
        const uploadPayload = {
          ...uploadPayload,
          payloadData: { job_id, candidate_uid, payload: { reason_id } },
          updateStatusSubStatus: status,
        };
        this.directAction(uploadPayload);
        return;
      }

      this.actionModal["jobDetails"] = { ...jobDetails?.job };
      this.actionModal["candidateDetails"] = { ...jobDetails?.candidate };
      this.actionModal["details"] = jobDetails;
      this.actionModal["action"] = selectedAction;

      let checkError = {
        isError: false,
        message: "",
      }
      const { job } = jobDetails;
      const jobUsers = job?.job_candidate_users && job?.job_candidate_users.map(({ customer_user }) => customer_user?.customer_user_type_id) || [];
      if (action_id === actionData.submission) {
        if (!jobUsers.includes(1)) {
          checkError = {
            isError: true,
            message: "No Reviewer Found ! Please update a Submission Reviewer for the job !!"
          };
        }
      } else if (action_id === actionData.interview) {
        if (!jobUsers.includes(2)) {
          checkError = {
            isError: true,
            message: "No Interviewer Found ! Please update a Candidate Interviewer for the job !!"
          };
        }
      }
      if (checkError.isError) {
        this.showToast({
          class: "bg-danger text-white",
          message: checkError?.message,
        })
      } else {
        if (action_id === actionData.submission) {
          this.submissionModal["isShowPopup"] = true;
        } else if (action_id === actionData.interview) {
          this.interViewModal["job_id"] = job_id;
          this.interViewModal["details"] = jobDetails;
          this.interViewModal["action"] = selectedAction;
          this.interViewModal["isShowPopup"] = true;
        } else if (action_id === actionData.offer) {
          this.openCheckList(jobDetails, 1, "offer");
        } else if (action_id === actionData.onboard) {
          this.openCheckList(jobDetails, 1, "licensing");
        } else if (action_id === actionData.fastTrack) {
          this.fastTrackModal["job_id"] = job_id;
          this.fastTrackModal["details"] = jobDetails;
          this.fastTrackModal["action"] = selectedAction;
          this.fastTrackModal.isShowPopup = true;
          this.fastTrackModal["candidate"] = candidate;
          this.fastTrackModal["job"] = jobDetails;
        } else if (action_id === actionData.error) {
          this.errorModal.job = jobDetails;
          this.errorModal.candidate = candidate;
          this.errorModal.action = selectedAction
          this.errorModal["isShowPopup"] = true;
        } else if (action_id === actionData.documents) {
          this.offerOnboardModal.isShowPopup = true;
          this.offerOnboardModal.title = "Candidate Offer";
          this.offerOnboardModal["job_id"] = job_id;
          this.offerOnboardModal["data"] = {
            candidate,
            ...jobDetails,
          };
          this.offerOnboardModal["action"] = selectedAction;
          this.offerOnboardModal.isOffer = true;
          this.offerOnboardModal.isOnBoard = false;
        } else {
          this.actionModal["isShowPopup"] = true;
        }
      }
    },
    actionModalCallBack(action, payload) {
      this.actionModal.isShowPopup = false;
      if (!action) this.actionModal.isShowPopup = false;
      if (action && payload) {
        const { jobDetails, candidateDetails } = this.actionModal;
        const { job_id } = jobDetails;
        const { candidate_uid } = candidateDetails;
        const appentAction = this.initUpdateJobStatus({
          candidate_uid,
          job_id,
          payload: { ...payload, isFromDashBoard: true },
        });
        Promise.all([appentAction]).then((res) => {
          if (res) {
            this.actionModal.isShowPopup = false;
            // this.fetchCandidateInterview();
            this.callForActions();
          }
        });
      }
    },
    fastTrackModalCallBack(action, payload) {
      this.fastTrackModal.isShowPopup = false;
      if (!action) this.fastTrackModal.isShowPopup = false;
      if (action && payload) {
        const { job_id, candidate } = this.fastTrackModal;
        const { candidate_uid } = candidate;
        let uploadPayload = {
          updateStatusSubStatus: {
            update_status: 54,
            update_sub_status: 55,
          },
        };
        uploadPayload = {
          ...uploadPayload,
          payloadData: { job_id, candidate_uid, payload: { ...payload, isFromDashBoard: true } },
        };
        this.updateCandidateJobStatus(uploadPayload).then((res) => {
          this.fastTrackModal.isShowPopup = false;
          this.fastTrackModal.job_id = null;
          this.callForActions();
        });
      }
    },
    errorModalCallBack(action, payload) {
      this.errorModal.isShowPopup = false;
      if (!action) this.errorModal.isShowPopup = false;
      if (action && payload) {
        const { job } = this.errorModal;
        const { candidate } = this.errorModal;
        const { job_id } = job;
        const { candidate_uid } = candidate;
        const { status_id: update_status, sub_status_id: update_sub_status, comments } = payload;
        let uploadPayload = {
          updateStatusSubStatus: {
            update_status,
            update_sub_status,
          },
        };
        uploadPayload = {
          ...uploadPayload,
          payloadData: { job_id, candidate_uid, payload: { comments, isFromDashBoard: true } },
        };
        this.updateCandidateJobStatus(uploadPayload).then((res) => {
          this.errorModal.isShowPopup = false;
          this.callForActions();
        });
      }
    },
    submissionModalCallBack(action) {
      this.submissionModal["isShowPopup"] = false;
      if (action === "Yes") {
        this.actionModal["isShowPopup"] = true;
      } else {
        this.actionModal["isShowPopup"] = false;
      }
    },
    interViewModalCallBack(action) {
      this.interViewModal["isShowPopup"] = false;
      if (action === "Yes") {
        this.actionModal["isShowPopup"] = true;
      } else {
        this.actionModal["isShowPopup"] = false;
      }
    },
  },
}