import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {
            checkListModal: {
                isShowPopup: false,
                candidateDetails: null,
                jobDetails: null,
                actionInfo: null,
                isActionCompleted: false,
            }
        }
    },
    methods: {
        ...mapActions(["createDocumentAction"]),
        openCheckList(data = {}, activeTab = 0, accordion = null) {
            const { candidate, job } = data
            this.checkListModal.isShowPopup = true;
            this.checkListModal.actionInfo = data;
            this.checkListModal.candidateDetails = candidate;
            this.checkListModal.jobDetails = job;
            this.checkListModal.activeTab = activeTab;
            this.checkListModal.accordion = accordion;
        },
        checkListModalCallBack(action, data) {
            this.checkListModal.isShowPopup = false;
            this.callForActions();
        },
        uploadDocActionCallBack(payload) {
            this.checkListModal.isActionCompleted = false;
            if (payload) {
                const { documentPayload, processPayload } = payload;
                let appendAction = [];
                this.offerOnboardModal.isShowPopup = false;
                documentPayload.forEach((element) => {
                    appendAction = [...appendAction, this.createDocumentAction(element)];
                });
                return Promise.all(appendAction).then(async (res) => {
                    if (processPayload) await this.initUpdateJobStatus(processPayload);
                    this.checkListModal.isActionCompleted = true;
                    return;
                });
            }
            return
        },
    }
}