<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      :centered="true"
    >
     <template #header>
        <h6 class="modal-title">Slots</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >OK</CButton
        >
      </template>
      <div class="documentScrolling document-table">
        <table class="table">
          <thead>
            <th>Slot</th>
            <th>Date</th>
            <th>Start Time</th>
            <th>End Time</th>
          </thead>
          <tbody>
            <tr v-for="(data, index) in slots" :key="index">
              <td>{{ data.name }}</td>
              <td>{{ data.date | formatDate }}</td>
              <td>{{ data.start_time | formatTime }}</td>
              <td>{{ data.end_time | formatTime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </CModal>
  </div>
</template>
<script>
import m from "moment";
export default {
  props: {
    slots: {
      type: Array,
      default: () => [],
    },
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
  },
  filters: {
    formatDate(date) {
      if (date) return m(date).format("DD MMM YYYY");
      return "--";
    },
    formatTime(date) {
      if (date){
          const dateObj = new Date();
          const dateSplit = date.split(":");
          dateObj.setHours(dateSplit[0],dateSplit[1],dateSplit[2])
          return m(dateObj).format("hh:mm A");
      } 
      return "--";
    },
  },
  methods:{
      modalCallBack(action){
          this.$emit("modalCallBack",action)
      }
  }
};
</script>